<template>
  
    <div class="h-full w-full flex flex-col relative">

        <loader v-if="firstTime" :loading="loading" class="bg-module"/>

        <div class="h-16 flex-none flex flex-row justify-start items-center px-10">

            <span class="text-2xl text-aux mr-auto">ALBARANES</span>

            <span v-if="lastSyncro" class="text-gray text-2xl text-thiny">Últ. Actualización <span class="text-white capitalize">{{lastSyncro.date | moment('DD MMM')}}</span> {{lastSyncro.date | moment('HH:mm')}}h</span>

        </div>

        <div v-if="!loading" class="flex-1 min-h-0 flex flex-row">

            <div class="h-full w-1/2 border-r border-gray flex flex-col">
            
                <div class="h-auto pb-2 flex flex-col justify-center items-center">

                    <span class="text-lightblue text-2xl mb-4">HOY</span>
                    
                    <div v-if="global_today" class="h-auto w-full px-8 flex flex-row justify-between items-center">
                        <span class="text-white text-2xl">{{global_today.amount || 0 | reduceBigNumbers(0)}} €</span>
                        <span class="text-white text-2xl">{{global_today.meters || 0 | reduceBigNumbers(0)}} m<sup>2</sup></span>
                        <span class="text-white text-2xl">{{global_today.count || 0 | reduceBigNumbers(0)}} Alb</span>
                    </div>

                </div>

                <div v-if="today && today != []" class="flex-1 w-full px-8 flex flex-row">

                   <div class="flex-1 flex flex-col">

                       <div class="h-auto flex flex-col justify-start items-start">

                            <span class="text-purple text-2xl">Nacional</span>
                            <span v-if="today[1]" class="text-xl text-purple -mt-2">{{today[1].percentage || 0 | numberFormat()}}%</span>

                       </div>

                       <div v-if="today[1]" class="flex-1 flex flex-col justify-center items-start">

                            <span class="text-gray text-2xl">{{today[1].amount || 0 | reduceBigNumbers(0)}} €</span>
                            <span class="text-gray text-2xl">{{today[1].meters || 0 | reduceBigNumbers(0)}} m<sup>2</sup></span>
                            <span class="text-gray text-2xl">{{today[1].count || 0 | reduceBigNumbers(0)}}</span>

                       </div>

                    </div>

                    <div class="h-full w-8 flex flex-col bg-box rounded-full">

                        <div v-if="today[0]" class="border-4 w-6 mx-auto border-yellow bg-yellow-transparent rounded-t-lg" :style="'height:'+ today[0].percentage +'%'"></div>
                        <div v-if="today[1]" class="border-4 w-6 mx-auto border-purple bg-purple-transparent rounded-b-lg" :style="'height:'+ today[1].percentage +'%'"></div>

                    </div>

                    <div class="flex-1 flex flex-col justify-start">

                       <div class="h-auto flex flex-col justify-start items-end">

                            <span class="text-yellow text-2xl">Exportación</span>
                            <span v-if="today[0]" class="text-xl text-yellow -mt-2">{{today[0].percentage || 0 | numberFormat()}}%</span>

                       </div>

                       <div v-if="today[0]" class="flex-1 flex flex-col justify-center items-end">

                            <span class="text-gray text-2xl">{{today[0].amount || 0 | reduceBigNumbers(0)}} €</span>
                            <span class="text-gray text-2xl">{{today[0].meters || 0 | reduceBigNumbers(0)}} m<sup>2</sup></span>
                            <span class="text-gray text-2xl">{{today[0].count || 0 | reduceBigNumbers(0)}}</span>

                       </div>

                    </div>

                </div>

                <div v-else class="flex-1 w-full flex flex-col justify-center items-center">

                   <span class="text-gray text-xl">No hay datos de hoy</span>

                </div>
            
            </div>

            <div v-if="month && month != [] && global_month" class="h-full w-1/2 flex flex-col">
            
                <div class="h-auto pb-2 flex flex-col justify-center items-center">

                    <span class="text-lightblue text-2xl uppercase mb-4">{{actualmonth}}</span>
                    
                    <div v-if="global_month" class="h-auto w-full px-8 flex flex-row justify-between items-center">
                        <span class="text-white text-2xl">{{global_month.amount || 0 | reduceBigNumbers(0)}} €</span>
                        <span class="text-white text-2xl">{{global_month.meters || 0 | reduceBigNumbers(0)}} m<sup>2</sup></span>
                        <span class="text-white text-2xl">{{global_month.count || 0 | reduceBigNumbers(0)}} Alb</span>
                    </div>

                </div>

                <div class="flex-1 w-full px-8 flex flex-row">

                   <div class="flex-1 flex flex-col">

                       <div class="h-auto flex flex-col justify-start items-start">

                            <span class="text-purple text-2xl">Nacional</span>
                            <span v-if="month[1]" class="text-xl text-purple -mt-2">{{month[1].percentage || 0 | numberFormat() }}%</span>

                       </div>

                       <div v-if="month[1]" class="flex-1 flex flex-col justify-center items-start">

                            <span class="text-gray text-2xl">{{month[1].amount || 0 | reduceBigNumbers(0)}} €</span>
                            <span class="text-gray text-2xl">{{month[1].meters || 0 | reduceBigNumbers(0)}} m<sup>2</sup></span>
                            <span class="text-gray text-2xl">{{month[1].count || 0 | reduceBigNumbers(0)}}</span>

                       </div>

                    </div>

                    <div class="h-full w-8 flex flex-col bg-box rounded-full">

                        <div v-if="month[0]" class="border-4 w-6 mx-auto border-yellow bg-yellow-transparent rounded-t-lg" :style="'height:' + month[0].percentage +'%'"></div>
                        <div v-if="month[1]" class="border-4 w-6 mx-auto border-purple bg-purple-transparent rounded-b-lg" :style="'height:'+ month[1].percentage  +'%'"></div>

                    </div>

                    <div class="flex-1 flex flex-col justify-start">

                       <div class="h-auto flex flex-col justify-start items-end">

                            <span class="text-yellow text-2xl">Exportación</span>
                            <span v-if="month[0]" class="text-xl text-yellow -mt-2">{{month[0].percentage || 0 | numberFormat() }}%</span>

                       </div>

                       <div v-if="month[0]" class="flex-1 flex flex-col justify-center items-end">

                            <span class="text-gray text-2xl">{{month[0].amount || 0 | reduceBigNumbers(0)}} €</span>
                            <span class="text-gray text-2xl">{{month[0].meters || 0 | reduceBigNumbers(0)}} m<sup>2</sup></span>
                            <span class="text-gray text-2xl">{{month[0].count || 0 | reduceBigNumbers(0)}}</span>

                       </div>

                    </div>

                </div>
            
            </div>

            <div v-else class="h-full w-1/2 border-r border-gray flex flex-col">
            
                <div class="h-auto py-2 flex flex-col justify-center items-center">

                    <span class="text-lightblue text-2xl">{{actualmonth}}</span>

                </div>

                <div class="flex-1 w-full px-8 flex flex-row flex flex-col justify-center items-center">

                    <span class="text-gray">No hay datos de {{actualmonth}}</span>

                </div>
            
            </div>

        </div>

        <div class="h-56 flex flex-col">
            
            <div class="h-16 flex flex-row justify-start items-center px-10">

                <span class="text-2xl text-aux mr-auto">CARGA DIARIA MES</span>

            </div>

            <div v-if="chartdata" class="flex flex-1 min-h-0 relative">

                <loader :loading="loadingChart" class="bg-module" />

                <!-- <comparativechart class="h-full w-full"  :data="chartdata" :values="false"/> -->
                <comparativechart-delivery-notes class="h-full w-full" :data="chartdata" :values="false" />

            </div>

        </div>

    </div>

</template>

<script>
import comparativechartDeliveryNotes from '../components/charts/comparativechartDeliveryNotes';
import loader from './loader.vue';
import { state } from '@/store';

export default {
    props:['lastSyncro'],
    components:{
        comparativechartDeliveryNotes,
        loader
    },
    data(){
        return{
            loading:false,
            global_today:false,
            global_month:false,
            today:false,
            month:false,
            chartdata:false,
            actualmonth: this.$moment().format('MMMM'),
            loadingChart: false,
            firstTime: true
        }
    },
    methods:{
        load(){
            this.loading = true;
            this.loadingChart = true;

            this.axios.get('/delivery_notes/data', {params: {period:'current_month'}}).then(response => {
                this.loading = false;
                this.global_month = response.data.data.global
                this.month = response.data.data.by_types
            }).catch(error => {
                this.loading = false;
            })

            this.axios.get('/delivery_notes/data', {params: {period:'day'}}).then(response => {
                this.loading = false;
                this.global_today = response.data.data.global
                this.today = response.data.data.by_types
            }).catch(error => {
                this.loading = false;
            })

            this.axios.get('/delivery_notes/historic').then(response => {
                this.loadingChart = false;
                this.chartdata = [response.data.data[1].past, response.data.data[0].current, response.data.data[2].current_day];
            }).catch(error => {
                this.loadingChart = false;
            })
        }
    },
    computed:{
       
    },
    mounted(){
        this.load();
        setInterval(() => {
            this.firstTime = false;
            this.load();
        }, state.timeRefresh);
    }
}
</script>

<style>

</style>