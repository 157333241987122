<template>
    <div class="h-full w-full" ref="container-chart">
        <highcharts :options="options" ref="columnchart" class="chart-component h-full w-full" />
    </div>
</template>

<script>
export default {
    props:['data', 'limit'],
    data(){
        return{

        }
    },
    methods:{

    },
    computed:{
        options(){
            return{
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    margin: [0,0,0,0]
                },
                title: {
                    text: '',
                },
                credits:{
                    enabled: false
                },
                legends:{
                    enabled: false
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
                    enabled: false
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: false,
                            distance: -50,
                            style: {
                                fontWeight: 'bold',
                                color: 'white'
                            }
                        },
                        startAngle: 0,
                        endAngle: 360,
                        center: ['50%', '50%'],
                        size: '100%',
                        borderWidth: 0
                    }
                },
                series: [{
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '80%',
                    data: [
                        {
                            name: '',
                            y: this.data,
                            dataLabels: {
                                enabled: false
                            },
                            color: '#39AFC6'
                        },
                        {
                            name: '',
                            y: (100 - this.data),
                            dataLabels: {
                                enabled: false
                            },
                            color: '#363649B3',
                            
                        }
                        
                    ]
                },
                {
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '80%',
                    data: [
                        {
                            name: '',
                            y: this.limit,
                            dataLabels: {
                                enabled: false
                            },
                            color: 'rgba(0,0,0,0)'
                        },{
                            name: '',
                            y: 0.25,
                            dataLabels: {
                                enabled: false
                            },
                            color: 'rgba(0,0,0,0)',
                            borderColor: '#0d597e',
                            borderWidth: 4
                        },
                        {
                            name: '',
                            y: (100 - (this.limit + 0.25)),
                            dataLabels: {
                                enabled: false
                            },
                            color: 'rgba(0,0,0,0)'
                        }
                        
                    ]
                }]
            }
        }
    }
}
</script>