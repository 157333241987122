<template>

    <div class="h-full w-full" ref="container-chart">
        <highcharts :options="options" ref="columnchart" class="chart-component h-full w-full" />
    </div>

</template>

<script>
import { reduceBigNumbers } from '../../main.js';
import moment from 'moment';

export default {
    props:['data', 'unit', 'year'],
    data(){
        return{
            colors:['#3B83E6', '#159782', '#7D5ADB']
        }
    },
    methods:{
        
    },
    computed:{
        options(){
            return {
                chart: {
                    type: 'column',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    margin: [30,40,50,75]
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                legend:{
                    enabled: false
                },
                xAxis: [
                    {
                        crosshair: true,
                        gridLineColor:'#FFF9F91',
                        lineColor: '#FFF9F91',
                        type: 'datetime',
                        labels:
                            this.year ?
                            {
                                formatter: function (){
                                    return '<span class="text-xl uppercase">' + moment(this.value).format('YYYY') + '</span>'
                                },
                                x: 0,
                                y: 30
                            }
                            :{
                                formatter: function (){
                                    return '<span class="text-xl uppercase">' + moment(this.value).format('MMM') + '</span>'
                                },
                                x: 0,
                                y: 30
                            } 
                    },
                    {
                        opposite:true,
                        lineColor: 'transparent',
                        tickColor: 'transparent',
                        type:'datetime',
                        labels:{
                            formatter: function () {
                                return ''
                            }
                        }
                    }
                ],
                yAxis: {
                    min: 0,
                    title: '',
                    gridLineColor:'rgba(132, 138, 157, .2)',
                    lineColor: '#FFF9F921',
                    labels:{
                        formatter: function (){
                            return '<span class="text-base">' + reduceBigNumbers(this.value,0) + ' € </span>'
                        },
                        x:-5,
                        y:7
                    }
                },
                credits:{
                    enabled:false
                },
                tooltip: {
                    enabled: true,
                    formatter: function() {

                        let result = '<span> ' + moment(this.x).format('DD MMM YYYY') + '</span> <br>'
                        
                        result += '<br> <span class="text-xxs">.</span> <br/> <span class="font-semibold text-xl">' + reduceBigNumbers(this.y) + '€</span>'

                        return result
                        
                    }
                },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderRadius: 3,
                        borderWidth: 5
                    }
                },
                series: [
                    {
                        name: 'Previous year',
                        data: this.data[0],
                        borderColor: '#315678',
                        color: '#31567884',
                        xAxis:0
                    },
                    {
                        name: 'Actual year',
                        data: this.data[1],
                        borderColor: '#39AFC6',
                        color: '#39AFC666',
                        xAxis: 1
                    }
                ]
            }
        },
        unity(){

            if(this.unit){

                return ''+ this.unit + ''

            } else {

                return ''

            }
        }
    }
}
</script>

<style>

</style>