<template>
    <div class="chart-wrap w-full h-full" ref="container-chart">
        <highmaps class="chart-container w-full h-full" :options="options" ref="map"></highmaps>
    </div>
</template>

<script>
import worldMapData from '../../utils/map.js';
import {reduceBigNumbers, numberFormat} from '../../main.js'

    export default {
        props: ['data','refMap','type'],
        data() {
            return {
                options: {
                    chart: {
                        borderWidth: 0,
                        map: worldMapData,
                        backgroundColor: 'rgba(0,0,0,0)',
                        margin:[0,0,0,0]
                    },

                    credits:{
                        enabled: false
                    },

                    title: {
                        text: ''
                    },

                    subtitle: {
                        text: ''
                    },

                    legend: {
                        enabled: false
                    },

                    plotOptions:{
                        
                    },

                    tooltip:{
                        enabled: true,
                        padding: 20,
                        useHTML: true,
                        formatter: function() {
                            return '<span class="font-semibold text-3xl">' + this.point.name + '</span><br><span class="font-semibold text-3xl" :style="color:' + this.point.color + '">'+ numberFormat(this.point.value,2) + ' %</span>';
                        }
                    },

                    mapNavigation: {
                        enabled: false,
                    },

                    colorAxis:{
                        labels: {
                            format: '{value}%'
                        }
                    },

                    series: [
                        {
                            name: '',
                            data: [],
                            joinBy: ['hc-key', 'code'],
                            nullColor: '#333753',
                            borderColor: '#2A2A2A'
                        }
                    ]
                }
            }
        },
        methods: {
            getData(){

                let chart = this.$refs['map'].chart;
                let countries = [];

                for (let index = 0; index < this.data.length; index++) {

                    if(this.data[index].iso){                    

                        countries.push(
                            {
                                name: this.data[index].name,
                                code: this.data[index].iso.toLowerCase(),
                                color: this.colormap(this.data[index].percentage),
                                value: this.data[index].percentage
                            }
                        )

                    }

                }

                chart.series[0].setData(countries)
                
            },
            colormap(value){

                if(value >= 5){

                    return '#6ECAA4'
                
                } else {

                    if(value > -5 && value < 5){
                        return '#FFEBCD'
                    } else{
                        
                        return '#E39D82'
                    }

                }

            }
        },
        watch: {
            data(){
                this.getData();
            }
        },
        mounted(){
            // this.getData();
        }
    };
</script>
