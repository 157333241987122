<template>
    
    <div class="h-full w-full relative">

        <loader :loading="loading" />

        <spainmapchart :data="spainMapData"/>

    </div>

</template>

<script>
    import spainmapchart from './charts/spainmapchart.vue';
    import loader from './loader.vue';

    export default {
        components:{
            spainmapchart,
            loader,
        },
        data(){
            return{
                spainMapData:false,
                loading:false
            }
        },
        methods:{
            
            load(){

                this.axios.get('/sale_by_province/data').then(response => {
                    this.spainMapData = response.data.data;
                    this.loading = false;
                }) 


            }

        },
        mounted(){
            this.load();
        }
    }

</script>