<template>

    <div class="h-full w-full" ref="container-chart">
        <highcharts :options="options" ref="gaugeChart" class="chart-component h-full w-full" />
    </div>

</template>

<script>

    export default {
        props: ['data', 'limit', 'color'],
        data() {
            return {
                options: {
                    chart: {
                        type: 'solidgauge',
                        backgroundColor: 'rgba(255, 255, 255, 0)',
                        margin: [0, 0, 0, 0],
                    },
                    credits:{ enabled: false },
                    legend: { enabled:false },
                    title: { text: ''},
                    pane: {
                        startAngle: 0,
                        endAngle: 360,
                        background: [{ // Track for Move
                            outerRadius: '110%',
                            innerRadius: '90%',
                            backgroundColor:'#292F35',
                            borderWidth: 0
                            }, 
                            { // Track for Exercise
                            outerRadius: '85%',
                            backgroundColor:'#292F35',
                            innerRadius: '65%',
                            borderWidth: 0
                            }
                        ]
                    },
                    tooltip: { enabled: false },
                    yAxis: {
                        min: 0,
                        max: 100,
                        lineWidth: 0,
                        tickPositions: [],
                        plotLines: [],
                    },
                    plotOptions: {
                        solidgauge: {
                            dataLabels: { enabled: false },
                            linecap: 'round',
                            stickyTracking: false,
                            rounded: true
                        }
                    },
                    series: [
                        {
                            name: 'Move',
                            data: [
                                { y:0, radius: '110%', innerRadius: '90%', color: this.color },
                            ]
                        },
                        {
                            name: 'Move',
                            data: [
                                { y:0, radius: '85%', innerRadius: '65%', color: this.color+'40' },
                            ]
                        }
                    ]
                }
            }
        },
        mounted() {
            this.updateChart();     
            if (this.limit) { this.updateLimit() }
            setTimeout(() => {
                this.chart.redraw();
            }, 300);
        },
        methods: {
            updateChart() {
                if (this.data[0]) { this.chart.series[0].points[0].update(this.data[0]) }
                if (this.data[1]) { this.chart.series[1].points[0].update(this.data[1]) }
            },
            updateLimit() {
                this.chart.yAxis[0].removePlotLine('limit')
                this.chart.yAxis[0].addPlotLine({ 
                    color: '#FF8443', 
                    width: 3, 
                    value: this.limit,
                    zIndex: 10,
                    id: 'limit'
                })
            }
        },
        computed: {
            chart() { return this.$refs['gaugeChart'].chart }
        },
        watch: {
            data(n, o) {
                if (JSON.stringify(n) !== JSON.stringify(o)) {

                    this.updateChart();

                    setTimeout(() => {
                        this.chart.redraw();
                    }, 100);
                    
                }
            },
            limit(n, o) {
                if (JSON.stringify(n) !== JSON.stringify(o)) { this.updateLimit() }
            }
        },
    }

</script>
