<template>
    
    <div class="h-full w-full relative">

        <loader :loading="loading" />

        <europemapchart :data="europeMapData"/>

    </div>

</template>

<script>
    import europemapchart from './charts/europemapchart.vue';
    import loader from './loader.vue';

    export default {
        components:{
            europemapchart,
            loader
        },
        data(){
            return{
                europeMapData:false,
                loading:false
            }
        },
        methods:{
            
            load(){

                this.axios.get('/sale_by_country/data').then(response => {
                    this.europeMapData = response.data.data;
                    this.loading = false;
                }) 

            }

        },
        mounted(){
            this.load();
        }
    }

</script>