<template>

    <div class="h-16 w-full flex flex-row border-l-8 border-lightblue rounded-lg bg-module mb-4">

        <div class="h-full w-8/12 flex flex-row justify-start items-center px-4">
            <span class="text-xl text-gray font-semibold truncate">{{data.name}}</span>        
        </div>

        <div class="h-full w-4/12 flex flex-row justify-center items-center">

            <i v-if="data.year > 0" class="mdi mdi-menu-up text-green text-4xl"></i>
            <i v-if="data.year < 0" class="mdi mdi-menu-down text-danger text-4xl"></i>

            <span class="text-2xl" :class="{'text-green': data.year > 0, 'text-danger': data.year < 0}"><span v-if="data.year >=0">+</span>{{data.year | reduceBigNumbers()}} %</span>

        </div>

    </div>

</template>

<script>
export default {
    props:['data'],
    data(){
        return{

        }
    }
}
</script>

<style>

</style>