<template>

    <div class="h-full w-full flex flex-col relative">

        <loader v-if="firstTime" :loading="loading" class="bg-module"/>

        <div class="h-16 flex flex-row justify-start items-center px-10 flex-none">

            <span class="text-2xl text-aux mr-auto">ORDENES DE CARGA</span>
            
            <span v-if="lastSyncro" class="text-gray text-2xl text-thiny">Últ. Actualización <span class="text-white capitalize">{{lastSyncro.date | moment('DD MMM')}}</span> {{lastSyncro.date | moment('HH:mm')}}h</span>

        </div>

        <div v-if="loadingOrder" class="flex-1 flex flex-col px-10">

            <div class="flex-1 border-b border-gray flex flex-col">

                <div class="h-auto my-auto flex flex-row">

                    <div class="h-auto w-56 flex flex-col justify-center items-start">

                        <span class="text-2xl text-lightblue uppercase">OC HOY</span>
                        <span v-if="loadingOrder.today" class="text-white font-semibold text-4xl">{{loadingOrder.today.amount | reduceBigNumbers()}} €</span>

                    </div>

                    <div class="h-auto flex-1 flex flex-col justify-center items-center">

                        <div class="h-20 w-full rounded-md bg-box flex flex-row justify-start items-center relative">

                            <div v-if="loadingOrder.today" class="absolute h-12 w-full mx-auto flex flex-row justify-start -mt-32 mb-4">
                                <div class="h-full w-auto z-20 flex flex-col justify-center items-center" :style="'margin-left: ' + (loadingOrder.today.avg_percentage - 6 ) + '%; transition: all .3s;'">
                                    <span v-if="loadingOrder.today.avg_percentage < 100" class="text-gray text-4xl mt-4">{{loadingOrder.today.avg | reduceBigNumbers()}} €</span>
                                </div>
                            </div>

                            <div v-if="loadingOrder.today" class="h-8 bg-lightblue rounded-l" :class="{'rounded-r': 99 == 100}" :style="'width: ' + loadingOrder.today.amount_percentage + '%'" style="transition: all .3s"></div>

                            <div v-if="loadingOrder.today" class="absolute top-0 left-0 w-full h-full -px-5">
                                <div class="h-20 w-2 bg-gray z-20 shadow" :style="'margin-left: ' + loadingOrder.today.avg_percentage + '%; transition: all .3s;'"></div>
                            </div>

                        </div>

                        <div class="h-0 w-full"></div>

                    </div>

                    <div class="h-full w-48 flex flex-col justify-center items-end">

                        <span v-if="loadingOrder.today" class="text-gray text-4xl">{{loadingOrder.today.max | reduceBigNumbers()}} €</span>

                    </div>


                </div>

                <div class="h-auto flex flex-row justify-start items-center mt-auto">
                    <span class="text-lightblue text-2xl uppercase mr-auto">OC POR FECHA DE SERVICIO</span>
                    
                </div>

            </div>


            <div class="h-auto flex flex-row justify-start items-center mt-auto">
                <span v-if="loadingOrderTotal" class="text-white font-semibold text-4xl mr-auto">{{loadingOrderTotal | reduceBigNumbers()}} €</span>
                <div v-if="operative" class="h-full flex flex-row justify-end items-center -mb-8">
                    <span class="text-blue text-2xl uppercase text-center mr-4">OPERATIVAS</span>
                    <span class="text-2xl font-semibold text-blue">{{operative.total_without_no_operative | reduceBigNumbers()}} €</span>
                </div>
                <div class="h-full w-1/4"></div>
            </div>

            <div class="h-68 relative flex flex-row py-2 pb-4">

                <div class="h-full w-3/4 flex flex-row border border-blue border-dashed">
                
                    <div v-if="loadingOrder.previous" class="h-full w-1/3 py-2 ml-2 rounded-lg flex flex-row justify-end items-center px-2 relative">

                        <div class="h-full w-5/12 flex flex-col justify-center items-end">

                            <span class="text-gray -mb-2 text-2xl uppercase">{{loadingOrder.previous.name}}</span>
                            <span class="text-4xl font-semibold text-oc_prev mb-20">{{loadingOrder.previous.amount | reduceBigNumbers()}} €</span>

                        </div>
                        
                        <div class="h-full flex-1 min-w-0 relative flex flex-col justify-center items-center">

                            <doubledonutchart :data="[loadingOrder.previous.amount_percentage || 0,loadingOrder.previous.avg_percentage || 0]" color="#BAA483"/>

                        </div>

                    </div>

                    <div v-if="loadingOrder.operative_today" class="h-full w-1/3 py-2 ml-2 rounded-lg flex flex-row justify-end items-center px-2 relative">

                        <div class="h-full w-5/12 flex flex-col justify-center items-end">

                            <span class="text-gray -mb-2 text-2xl uppercase">{{loadingOrder.operative_today.name}}</span>
                            <span class="text-4xl font-semibold text-oc_operative mb-20">{{loadingOrder.operative_today.amount | reduceBigNumbers()}} €</span>

                        </div>

                        <div class="h-full flex-1 min-w-0 relative flex flex-col justify-center items-center">

                            <doubledonutchart :data="[loadingOrder.operative_today.amount_percentage || 0,loadingOrder.operative_today.avg_percentage || 0]" color="#96CCA6"/>

                        </div>

                    </div>

                    <div v-if="loadingOrder.future" class="h-full w-1/3 py-2 ml-2 rounded-lg flex flex-row justify-end items-center px-2 relative">

                        <div class="h-full w-5/12 flex flex-col justify-center items-end">

                            <span class="text-gray -mb-2 text-2xl uppercase">{{loadingOrder.future.name}}</span>
                            <span class="text-4xl font-semibold text-blue mb-20">{{loadingOrder.future.amount | reduceBigNumbers()}} €</span>

                        </div>
                        
                        <div class="h-full flex-1 min-w-0 relative flex flex-col justify-center items-center">

                            <doubledonutchart :data="[loadingOrder.future.amount_percentage || 0,loadingOrder.future.avg_percentage || 0]" color="#3B83E6"/>

                        </div>

                    </div>

                </div>

                <div class="h-full flex-1 min-w-0 ml-2 border-2 border-gray border-dashed">

                    <div v-if="loadingOrder.not_operative" class="h-full w-full py-2 ml-2 rounded-lg flex flex-row justify-end items-center px-2 relative">

                        <div class="h-full w-5/12 flex flex-col justify-center items-end">

                            <span class="text-gray -mb-2 text-2xl uppercase">{{loadingOrder.not_operative.name}}</span>
                            <span class="text-4xl font-semibold text-gray mb-20">{{loadingOrder.not_operative.amount | reduceBigNumbers()}} €</span>

                        </div>
                        
                        <div class="h-full flex-1 min-w-0 relative flex flex-col justify-center items-center">

                            <doubledonutchart :data="[loadingOrder.not_operative.amount_percentage || 0,loadingOrder.not_operative.avg_percentage || 0]" color="#838A9C"/>

                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>

</template>

<script>
import doubledonutchart from '../components/charts/doubledonutchart.vue';
import loader from './loader.vue';
import { state } from '@/store';

export default {
    props:['lastSyncro'],
    components:{
        doubledonutchart,
        loader
    },
    data(){
        return{
            loadingOrder: false,
            loadingOrderTotal: false,
            loading: false,
            operative:false,
            firstTime: true
        }
    },
    methods:{

        load(){

            this.loading = true;

            this.axios.get('/loading_order').then(response => {

                this.loadingOrder = response.data.data
                this.loadingOrderTotal = response.data.total
                this.operative = response.data
                this.loading = false;

            })

        }

    },
    mounted(){
        this.load();

        setInterval(() => {
            this.firstTime = false;
            this.load();
        }, state.timeRefresh);
    }
}
</script>