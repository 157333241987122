<template>
  
    <div class="h-full w-full flex flex-row relative">

        <loader v-if="firstTime" :loading="loading" class="bg-module" />

        <div class="h-full w-9/12 flex flex-col">

            <div class="h-24 flex flex-row">

                <div class="h-full w-auto flex flex-col">

                    <div class="h-16 w-auto flex flex-row justify-start items-center px-10 flex-none">

                        <span class="text-2xl text-aux mr-auto">EVOLUCIÓN FACTURACIÓN AÑO</span>

                    </div>

                    <div class="h-8 w-auto flex flex-row justify-start items-center px-10 ">

                        <div class="h-auto flex -mt-6 mr-auto flex-row justify-start items-center">
                            <span class="text-2xl text-aux mr-1">{{yearact}}</span> 
                            <span class="text-gray text-2xl">vs {{prevyear}}</span>
                        </div>

                    </div>

                </div>

                <div class="h-full flex-1 min-w-0 flex flex-row">

                    <div class="flex-1 min-w-0 flex flex-row justify-end items-center mr-12">

                        <div class="flex flex-row jusity-start items-center mr-12">
                            <span class="text-aux text-2xl mr-6 mb-6">TOTAL ALCANCE</span>
                            <div class="h-full flex flex-col justify-center items-end">
                                <span class="text-white text-3xl">{{scope | reduceBigNumbers()}} €</span>
                                <span class="font-semibold text-2xl -mt-2" :class="{'text-green': growth_bill > 0, 'text-danger': growth_bill <= 0}"><span v-if="growth_bill>0">+</span>{{growth_bill | numberFormat(2)}}%</span>
                            </div>
                        </div>

                        <div class="flex w-auto flex-col jusity-center items-end mr-12">
                            <span class="text-white text-3xl">{{meters | reduceBigNumbers()}} m<sup>2</sup></span>
                            <span class="font-semibold text-2xl -mt-2" :class="{'text-green': growth_meters > 0, 'text-danger': growth_meters <= 0}"><span v-if="growth_meters>0">+</span>{{growth_meters | numberFormat(2)}}%</span>
                        </div>

                        <div class="flex w-auto flex-col jusity-center items-end">
                            <span class="text-white text-3xl">{{medium_price | reduceBigNumbers()}} €/m<sup>2</sup></span>
                            <span class="font-semibold text-2xl -mt-2" :class="{'text-green': growth_medium_price > 0, 'text-danger': growth_medium_price <= 0}"><span v-if="growth_medium_price>0">+</span>{{growth_medium_price | numberFormat(2)}}%</span>
                        </div>

                    </div>

                </div>

            </div>

            <div class="flex-1 min-h-0 flex flex-row px-4 mt-2 border-r border-dashed border-gray">

                <columncomparechart :data="colcomparedata"/>

            </div>

        </div>

        <div class="h-full w-3/12 flex flex-col pr-4">

            <div class="h-16 flex flex-col justify-end items-end pr-12">
                <span class="text-lightgreen text-xl">Alcance</span>
                <span class="font-semibold -mt-2 text-lightgreen text-xl">{{scope || 0 | reduceBigNumbers()}} €</span>
                <span class="font-semibold -mt-2 text-lightgreen text-xl -mb-4"><span v-if="growth_bill >= 0">+</span>{{growth_bill || 0 | reduceBigNumbers()}}%</span>
            </div>

            <div class="w-full flex-1 min-h-0">
                <columncomparechart-years :data="colcomparedatayear" :scope="scope" :growth_bill="growth_bill"/>               
            </div>

        </div>

    </div>

</template>

<script>
import columncomparechart from './charts/columncomparechart.vue';
import columncomparechartYears from './charts/columncomparechartYears.vue';
import loader from './loader.vue';
import moment from 'moment';
import { state } from '@/store';

export default {
    components:{
        columncomparechart,
        columncomparechartYears,
        loader,
        moment
    },
    data(){
        return{
            colcomparedata: false,
            colcomparedatayear:[0,0],
            yearact: this.$moment().format('YYYY'),
            prevyear: this.$moment().subtract('years', 1).format('YYYY'),
            loading: false,
            scope: 0,
            year: 0,
            growth_bill:0,
            meters:0,
            growth_meters:0,
            medium_price:0,
            growth_medium_price:0,
            firstTime: true
        }
    },
    methods:{

        load(){

            this.loading = true;

            this.axios.get('/sale_by_year/data').then(response => {

                this.loading = false;
                this.colcomparedata = [response.data.data.amount_by_month_past_year, response.data.data.amount_by_month_year];
                this.colcomparedatayear = [response.data.data.past_year, response.data.data.year]
                this.scope = response.data.data.scope;
                this.year = response.data.data.year;
                this.growth_bill = response.data.data.growth_bill;
                this.meters = response.data.data.meters;
                this.growth_meters = response.data.data.growth_meters;
                this.medium_price = response.data.data.medium_price;
                this.growth_medium_price = response.data.data.growth_medium_price;
                

            })

        }

    },
    mounted(){

        this.load();
        setInterval(() => {
            this.firstTime = false;
            this.load();
        }, state.timeRefresh);

    }
}
</script>

<style>

</style>