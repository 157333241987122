<template>

    <div class="h-full w-full flex flex-col relative">

        <loader v-if="firstTime" :loading="loading" class="bg-module"/>

        <div class="h-16 flex flex-row justify-start items-center px-10">

            <span class="text-2xl text-aux mr-auto">ENTRADA PEDIDOS</span>

            <span v-if="lastSyncro && lastSyncro.date" class="text-gray text-2xl text-thiny">Últ. Actualización <span class="text-white capitalize">{{lastSyncro.date | moment('DD MMM')}}</span> {{lastSyncro.date | moment('HH:mm')}}h</span>

        </div>

        <div class="flex-1 flex flex-row min-h-0">

            <div class="h-full w-92 flex flex-col">

                <div class="h-auto flex flex-row justify-center items-center">
                    <span class="text-2xl text-lightblue -mb-4">HOY</span>
                </div>

                <div class="flex-1 min-h-0">

                    <div v-if="total" class="h-auto w-full mx-auto relative flex flex-col justify-center items-center">

                        <piechart-orders :data="total.line_percentage" :limit="total.avg_percentage"/>

                        <div class="h-64 w-64 rounded-full absolute flex flex-col justify-center items-center">

                            <span class="text-2xl text-lightblue">TOTAL</span>

                            <span class="text-white font-semibold text-5xl -my-4">{{total.line}}</span>

                            <span class="text-gray text-2xl">líneas</span>

                            <span class="text-white text-4xl font-semibold mt-2">{{total.amount | reduceBigNumbers()}} €</span>

                        </div>

                    </div>

                </div>

            </div>

            <div class="h-full flex-1 min-w-0 flex flex-col">


                <div class="h-82 w-full mt-auto flex flex-row">

                    <div class="h-full w-1/3">
                        <orderentrychart :data="picking"/>
                    </div>

                    <div class="h-full w-1/3">
                        <orderentrychart :data="brecha"/>
                    </div>

                    <div class="h-full w-1/3">
                        <orderentrychart :data="muestras"/>
                    </div>

                </div>

                <div class="h-16 min-h-0 w-full flex flex-row justify-center items-center">

                    <div class="h-6 w-16 rounded bg-box mr-4"></div>
                    <span class="text-2xl text-gray mr-10">Máximo</span>

                    <div class="h-1 w-16 bg-aux2 mr-4"></div>
                    <span class="text-2xl text-gray">Media</span>

                </div>


            </div>

        </div>

    </div>

</template>

<script>
import piechartOrders from './charts/piechartOrders.vue';
import orderentrychart from './charts/orderentrychart.vue';
import loader from './loader.vue';
import { state } from '@/store';

export default {
    props:['lastSyncro'],
    components:{
        piechartOrders,
        orderentrychart,
        loader
    },
    data(){
        return{
            picking:{
                name: 'PICKING',
                color: 'picking',
            },
            brecha:{
                name: 'BRECHA',
                color: 'brecha',
            },
            muestras:{
                name: 'MUESTRAS',
                color: 'muestras',
            },
            total:false,
            loading:false,
            firstTime: true
        }
    },
    methods:{
        load(){ 

            this.loading = true;

            this.axios.get('/order/entry').then(response => {

                let max = 0

                if(response.data.data.Picking.max > max){
                    max = response.data.data.Picking.max
                }

                if(response.data.data.Brecha.max > max){
                    max = response.data.data.Brecha.max
                }

                if(response.data.data.Muestra.max > max){
                    max = response.data.data.Muestra.max
                }
                
                this.total = response.data.data.total
                this.picking = {...this.picking, ...response.data.data.Picking, height:((response.data.data.Picking.max * 100)/max)}
                this.brecha = {...this.brecha, ...response.data.data.Brecha, height:((response.data.data.Brecha.max * 100)/max)}
                this.muestras = {...this.muestras, ...response.data.data.Muestra, height:((response.data.data.Muestra.max * 100)/max)}

                this.loading = false;
                
            }).catch(error =>{
                this.loading = false;
            })

        }
    },
    mounted(){

        this.load();
        setInterval(() => {
            this.firstTime = false;
            this.load();
        }, state.timeRefresh);
   
    }
}
</script>

<style>

</style>