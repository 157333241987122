  
<template>
    <div class="h-full w-full" ref="container-chart">
        <highcharts :options="options" ref="gaugeChart" class="chart-component h-full w-full" />
    </div>

</template>

<script>
let gradient ={
    linearGradient: {x1: 0,x2: 0,y1: 1,y2: 0},
    stops: [[0, '#21223387'],[1, '#6A85B187']]
}
let labelValue = 125;
import moment from 'moment';
import {reduceBigNumbers} from '../../main.js';
import { numberFormat } from 'highcharts';

export default {
    props:['data', 'margin', 'plotline', 'values'],
    data(){
        return{
            randomdata:[],
            lastPointValue: false
        }
    },
    methods:{
        lastPoint(){

            let value = false

            this.lastPointValue = this.data[2]

        },
        getColor(value){

            if(value <= 3,5){

                return '#6DAC5F'

            } else if(value <= 4,5){

                return '#ffe65d'
                
            } else {

                return '#E65454'

            }

        },
        number(number){

            if(number>=0){
                return '+'+number
            } else {
                return number
            }

        }
    },
    computed:{
        options(){
            return{
                chart: {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    margin:this.margin ? this.margin : [30,50,50,110]
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                xAxis: [
                    {
                        type: 'datetime',
                        tickInterval: !this.values ? undefined : 2629750000,
                        labels: 
                        this.values ? 
                            {
                                formatter: function (){
                                    return '<span class="text-lg uppercase mt-2">' + moment(this.value).format('MMM') + '</span>'
                                },
                                x: 0,
                                y: 30
                            }
                            :
                            {
                                // formatter: function (){
                                //     return ''
                                // }
                            }
                    },
                    {
                        opposite:true,
                        lineColor: 'transparent',
                        tickColor: 'transparent',
                        labels:{
                            formatter: function () {
                                return ''
                            }
                        },
                        plotLines: [

                            {
                                value: this.lastPointValue[0],
                                color: 'gray',
                                width: 2,
                                dashStyle: 'longdash',
                                label: {
                                    text: this.lastPointValue[1] ? reduceBigNumbers(this.lastPointValue[1]) + '€' : 0,
                                    style:{
                                        color: '#39AFC6',
                                        fontSize: '18px',
                                        fontWeight: '900',
                                        textShadow: '-1px 0 #131622, 0 1px #131622, 1px 0 #131622, 0 -1px #131622',
                                    },
                                    x: this.xLinesAlign,
                                    y: 80,
                                    rotation: 0,
                                    align: this.linesAlign
                                },

                                zIndex:5
                            
                            }
                        ],
                        type: 'datetime'
                    }
                ],
                yAxis: [{
                    title: '',
                    gridLineColor: 'rgba(132, 138, 157, .2)',
                    labels:{
                        formatter: function (){
                            return '<span class="text-2xl">' + reduceBigNumbers(this.value,0) + ' <small>€</small> </span>'
                        },
                    }
                    }, { // Secondary yAxis
                    gridLineColor: 'rgba(0, 0, 0, .4)',
                    opposite: false,
                    visible:false,
                    min:0
                }],
                tooltip: {
                    enabled: true,
                    shared: true,
                    // formatter: function(){
                    //     return '<p class="text-lg">(' + moment(this.x).format('DD MMM YYYY') + ')</p> <br>  <p class="font-semibold text-xl">' + reduceBigNumbers(this.y) + '€</p>'
                    // }
                    formatter: function () {

                        let result = '<span> ' + moment(this.x).format('DD MMM YYYY') + '</span> <br>'
                        
                        result += '<br> <span class="text-xxs">.</span> <br/> <span class="font-semibold text-xl">' + reduceBigNumbers(this.y) + '€</span>'

                        return result

                    }

                },
                legend: {
                    enabled:false
                },
                plotOptions: {
                    areaspline: {
                        marker: {
                            enabled: false
                        }
                    },
                    spline: {
                        marker: {
                            enabled: false
                        }
                    }                
                },
                credits:{
                    enabled:false
                },
                series: [
                    {
                        name: 'Past',
                        type: 'areaspline',
                        xAxis: 0,
                        data: this.data[0] ? this.data[0] : [],
                        color:gradient

                    }, 
                    {
                        name: 'Current',
                        type: 'spline',
                        data: this.data[1] ? this.data[1] : [],
                        xAxis:1,
                        color:'#39AFC6',
                        lineWidth:5
                    },
                    {
                        name: '',
                        type: 'line',
                        data: [this.lastPointValue],
                        xAxis:1,
                        color: '#39AFC6',
                        marker: {
                            radius: 8,
                            symbol: 'circle',
                            lineColor: '#131622',
                            lineWidth: 3
                        }
                    }
                ]
            }
        },
        linesAlign(){

            if(moment(this.data[2].date).format('MMM') == 'DIC'){

                return 'right'

            } else {

                return 'left'

            }

        },
        xLinesAlign(){

            if(moment(this.data[2].date).format('MMM') == 'DIC'){

                return -10

            } else {

                return 10

            }

        }

    },
    watch:{
        data(){
            this.lastPoint();
        }
    },
    mounted(){
        this.lastPoint();
    }
}
</script>