<template>
    
        <div class="loading" v-if="loading">
            <div class="loader-container">
                <div class="loader"></div>
            </div>
        </div>
    
</template>
<script>
import { state, actions } from '@/store';

    export default {
        props:['loading'],
        computed:{
            mode(){
                return state.mode
            }
        }
    }
</script>

<style lang="css" scoped>
    
    .loading{
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        z-index: 9000;
        /* background: white; */
        border-radius: 10px;
    }

        .loader-container{
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
            .loader,
            .loader:after {
                border-radius: 50%;
                width: 10em;
                height: 10em;

            }
            .loader { 
                margin: 0px;
                font-size: 4px;
                position: relative;
                
                border-top: 1.1em solid rgba(white, 0.8);
                border-right: 1.1em solid rgba(white, 0.8);
                border-bottom: 1.1em solid rgba(white, 0.8);
                border-left: 1.1em solid #39AFC6;
                transform: translateZ(0);
                animation: load8 1.1s infinite linear;
            }
            @-webkit-keyframes load8 {
                0% {
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                }
                100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                }
            }
            @keyframes load8 {
                0% {
                    -webkit-transform: rotate(0deg);
                    transform: rotate(0deg);
                }
                100% {
                    -webkit-transform: rotate(360deg);
                    transform: rotate(360deg);
                }
            }
    .fadeLoader-enter-active, .fadeLoader-leave-active {
        transition: all .5s;
    }

    .fadeLoader-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }



</style>