<template>

    <div class="h-full w-full" ref="container-chart">
        <highcharts :options="options" ref="columnchart" class="chart-component h-full w-full" />
    </div>

</template>

<script>
import { reduceBigNumbers } from '../../main.js';
import moment from 'moment';

export default {
    props:['data', 'unit', 'scope', 'growth_bill'],
    data(){
        return{
            colors:['#3B83E6', '#159782', '#7D5ADB']
        }
    },
    methods:{
        numberFunction(numberData){
            let number = parseInt(numberData)
            if( number >= 0){
                return '+'+numberData+'%'
            } else {
                return numberData+'%'
            }
        }
    },
    computed:{
        options(){
            return {
                chart: {
                    type: 'column',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    margin: [30,10,50,75]
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                legend:{
                    enabled: false
                },
                xAxis: {
                    gridLineColor:'#FFF9F921',
                    lineColor: '#FFF9F921',
                    categories:[moment().subtract(1, 'years').format('YYYY'), moment().format('YYYY')],
                    labels:
                        {
                            formatter: function (){
                                return '<span class="text-xl uppercase">' + this.value + '</span>'
                            },
                            x: 0,
                            y: 30
                        }
                },
                yAxis: {
                    min: 0,
                    title: '',
                    gridLineColor:'#FFF9F921',
                    lineColor: '#FFF9F921',
                    labels:{
                        formatter: function (){
                            return '<span class="text-base">' + reduceBigNumbers(this.value,0) + ' €</span>'
                        },
                        x:-5,
                        y:7
                    },
                    plotLines:[{
                        color: '#6ECAA4',
                        value: this.scope ? this.scope : 0,
                        width: 2,
                        dashStyle: 'longdash',
                        label: {
                            // text: '' + this.numberFunction(reduceBigNumbers(this.scope ? this.crecimiento : 0)) + '',
                            text:'',
                            style:{
                                color: '#6ECAA4',
                                fontSize: '20px',
                                fontWeight: '900'
                            },
                            x: 200,
                            y: -10
                        }
                    }]
                },
                credits:{
                    enabled:false
                },
                tooltip: {
                    enabled: false
                },
                plotOptions: {
                    column: {
                        borderRadius: 5,
                        borderWidth: 5,
                        grouping: false,
                    }
                },
                series: [
                    {
                        name: 'Previous year',
                        data: [this.data[0], null],
                        borderColor: '#315678',
                        color: '#31567866',
                        // pointPadding: 0.3,
                        // pointPlacement: -0.2,
                        dataLabels: {
                            enabled: true,
                            formatter: function(){
                                return '<span style="font-size: 20px; color: white; font-weight:100">' + reduceBigNumbers(this.y) + '</span>'
                            },
                            x:0,
                            y:10
                        },
                    },
                    {
                        name: 'Objective',
                        data: [null, this.scope],
                        borderColor: 'transparent',
                        color: '#6ECAA445',
                        dataLabels:{
                            enabled: false
                        }
                    },
                    {
                        name: 'Actual year',
                        data: [null, this.data[1]],
                        borderColor: '#39AFC6',
                        color: '#39AFC666',
                        dataLabels: {
                            enabled: true,
                            formatter: function(){
                                return '<span style="font-size: 20px; color: white; font-weight:100">' + reduceBigNumbers(this.y) + '</span>'
                            },
                            x:0,
                            y:10
                        },
                    }
                    
                ]
            }
        },
        unity(){

            if(this.unit){

                return ''+ this.unit + ''

            } else {

                return ''

            }
        },
        crecimiento(){

            if(this.growth_bill > 0){
                return '+'+this.growth_bill
            } else{
                return this.growth_bill
            }

        }
    }
}
</script>

<style>

</style>