<template>
  
    <div class="h-full w-full flex flex-col">

        <div class="h-16 flex flex-row justify-start items-center px-10">

            <!-- <span class="text-2xl text-aux mr-auto">ALBARANES - CARGA DIARIA MES</span>7 -->

            <span class="text-gray text-lg text-thiny">Últ. Actualización <span class="text-white">15 Oct</span> 09:15h</span>

        </div>

        <div class="flex-1">

            <comparativechart />

        </div>

    </div>

</template>

<script>
import comparativechart from './charts/comparativechart.vue';

export default {
    components:{
        comparativechart
    }
}
</script>

<style>

</style>