<template>

    <div class="h-full w-full flex flex-col relative">

        <loader v-if="firstTime" :loading="loading" class="bg-module" />

        <div class="h-16 w-full flex flex-row justify-start items-center px-10 flex-none">

            <span class="text-2xl text-aux mr-auto">CARTERA PEDIDOS</span>

            <span v-if="lastSyncro" class="text-gray text-2xl text-thiny">Últ. Actualización <span class="text-white capitalize">{{lastSyncro.date | moment('DD MMM')}}</span> {{lastSyncro.date | moment('HH:mm')}}h</span>

        </div>

        <div class="h-auto flex -mt-4 flex-row justify-start items-center ml-10">
            <span class="text-2xl text-lightblue mr-1">{{yearact}}</span> 
            <span class="text-gray text-2xl">vs {{prevyear}}</span>
        </div>

        <div v-if="chartData" class="flex-1 mt-6">

            <comparativechart :data="[chartData.past, chartData.current, chartData.current_day]" :values="true"/>

        </div>

    </div>

</template>

<script>
import comparativechart from '../components/charts/comparativechart.vue';
import loader from './loader.vue';
import { state } from '@/store';

export default {
    props:['lastSyncro'],
    components:{
        comparativechart,
        loader
    },
    data(){
        return{
            yearact: this.$moment().format('YYYY'),
            prevyear: this.$moment().subtract('years', 1).format('YYYY'),
            chartData:false,
            loading: false,
            firstTime: true
        }
    },
    methods:{
        
        load(){

            this.loading = true;

            this.axios.get('/order/historic').then(response => {

                this.chartData = response.data.data;
                this.loading = false;

            })
        }

    },
    computed:{
        
    },
    mounted(){

        this.load();

        setInterval(() => {
            this.firstTime = false
            this.load();
        }, state.timeRefresh);
    }
}
</script>

<style>

</style>