<template>

    <div class="h-full w-full" ref="container-chart">
        <highcharts :options="options" ref="columnchart" class="chart-component h-full w-full" />
    </div>

</template>

<script>
import { reduceBigNumbers } from '../../main.js';

export default {
    props:['data', 'unit', 'max'],
    data(){
        return{
            colors:['#3B83E6', '#159782', '#7D5ADB'],
            margin:[0,0,0,0]
        }
    },
    methods:{
        updateChart(){

        }
    },
    computed:{
        options(){
            return {
                chart: {
                    type: 'column',
                    // styledMode: true,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    margin: [10, 0, 1, 0]
                },
                credits:{
                    enabled: false
                },
                title: {
                    text: ''
                },
                xAxis:{
                    gridLineColor:'#FFF9F921',
                    lineColor: '#FFF9F921',
                    lineWidth: 3,
                    
                },
                yAxis:{
                    title:'',
                    gridLineColor:'rgba(255, 255, 255, 0.05)',
                    lineColor: '#FFF9F921',
                    lineWidth: 3,
                    labels: {
                        enabled: false,
                        formatter: function(){
                            return  '<span>'+reduceBigNumbers(this.value,0)+'</span>'
                        }      
                    },
                    max: this.max ? this.max : 0
                },
                plotOptions: {
                    series: {
                        borderRadius: 5,
                        borderWidth: 5,
                        dataLabels: {
                            enabled: true,
                            useHTML: true,
                            inside: false,
                            formatter: function(){
                                return '<span style="font-size: 20px; color: white; font-weight:100; text-shadow: 0px 0px 0px #191C2A">' + reduceBigNumbers(this.y,1) + '</span>'
                            }
                        }
                    },
                    // series:{
                    //     dataLabels:{
                    //         inside:false
                    //     }
                    // }
                },
                legend:{
                    enabled: false
                },
                tooltip:{
                    enabled:false
                },
                series: [
                    {
                        data: [{
                            y:this.data[0],
                            color: '#7D5ADB64',
                            borderColor: '#7D5ADB'
                        }]

                    }, 
                    {
                        data: [{
                            y:this.data[1],
                            color: '#70673D',
                            borderColor: '#EFD859'
                        }]
                    }, 
                    {
                        data: [{
                            y:this.data[2],
                            color: '#39AFC666',
                            borderColor: '#39AFC6'
                        }]
                    }
                ]
            }
        },
        unity(){

            if(this.unit){

                return ''+ this.unit + ''

            } else {

                return ''

            }
        }
    },
    mounted(){
        this.updateChart();
    }
}
</script>

<style>

    .highcharts-text-outline{
        text-shadow: none !important;
    }

    tspan{
        stroke: none !important;
    }

</style>