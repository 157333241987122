<template>
  
    <div class="h-full w-full flex flex-col relative overflow-hidden relative">

        <loader v-if="firstTime" :loading="loading" class="bg-body" />

        <div class="h-16 flex flex-row justify-start items-center px-10 z-10">

            <span class="text-2xl text-aux mr-auto uppercase">OBJETIVOS MENSUALES {{billingdatetitle | moment('MMMM')}}</span>

            <!-- <span class="text-gray text-lg text-thiny">Últ. Actualización <span class="text-white">15 Oct</span> 09:15h</span> -->

        </div>

        <div class="h-auto flex flex-row justify-start items-center pl-32 z-10">

            <div class="h-auto flex flex-col justify-start items-center mr-40">

                <span class="text-gray text-2xl uppercase">Facturación</span>

                <div class="h-82 w-82 relative flex flex-col justify-center items-center">
                    <piechart :data="[objective.outPercent || 0, objective.innerPercent || 0]"/>
                    <div class="h-56 w-56 rounded-full absolute flex flex-col justify-center items-center">
                        <span class="text-white font-semibold text-2xl">{{objective.scope || 0 | reduceBigNumbers()}} €</span>
                        <span class="text-xl font-semibold" :class="{'text-green': objective.percent >= 0, 'text-danger': objective.percent < 0 }"><span v-if="objective && objective.percent >= 0">+</span>{{objective.percent || 0 | numberFormat(2)}} %</span>
                        <span class="text-gray text-xl -mb-2">Objetivo</span>
                        <span class="text-white font-semibold text-2xl">{{objective.downValue || 0 | reduceBigNumbers()}} €</span>
                    </div>
                </div>

            </div>

            <div class="h-auto flex flex-col justify-start items-center mr-40">

                <span class="text-gray text-2xl uppercase">Precio Medio</span>

                <div class="h-82 w-82 relative flex flex-col justify-center items-center">
                    <piechart :data="[mediumPrice.outPercent || 0, mediumPrice.innerPercent || 0]"/>
                    <div class="h-56 w-56 rounded-full absolute flex flex-col justify-center items-center">
                        <span class="text-white font-semibold text-2xl">{{mediumPrice.thisYear || 0 | reduceBigNumbers()}} €/m<sup>2</sup></span>
                        <span class="text-xl font-semibold" :class="{'text-green': mediumPrice.percent >= 0, 'text-danger': mediumPrice.percent < 0 }"><span v-if="mediumPrice && mediumPrice.percent >= 0">+</span>{{mediumPrice.percent || 0 | numberFormat(2)}} €/m<sup>2</sup></span>
                        <span class="text-gray text-xl -mb-2">Objetivo</span>
                        <span class="text-white font-semibold text-2xl">{{mediumPrice.downValue || 0 | reduceBigNumbers()}} €/m<sup>2</sup></span>
                    </div>
                </div>

            </div>

            <div class="h-auto flex flex-col justify-start items-center mr-40">

                <span class="text-gray text-2xl uppercase">Margen</span>

                <div class="h-82 w-82 relative flex flex-col justify-center items-center">
                    <piechart :data="[margin.outPercent || 0, margin.innerPercent || 0]"/>
                    <div class="h-56 w-56 rounded-full absolute flex flex-col justify-center items-center">
                        <!-- <span class="text-white font-semibold text-2xl">{{margin.thisYear || 0 | reduceBigNumbers()}} %</span> -->
                        <span class="text-xl font-semibold" :class="{'text-green': margin.percent >= 0, 'text-danger': margin.percent < 0 }"><span v-if="margin && margin.percent >= 0">+</span>{{margin.percent || 0 | numberFormat(2)}}</span>
                        <!-- <span class="text-gray text-xl -mb-2">Objetivo</span> -->
                        <!-- <span class="text-white font-semibold text-2xl">{{margin.downValue || 0 | reduceBigNumbers()}} %</span> -->
                    </div>
                </div>

            </div>

        </div>

        <div class="absolute top-0 right-0 h-111 w-88 rounded-lg bg-module mr-5 flex flex-col shadow z-10">

            <loader :loading="recordLoading" class="bg-module" />

            <div class="h-16 flex flex-row justify-start items-center px-10">

                <span class="text-2xl text-aux mr-auto">RECORD HISTÓRICO</span>

                <!-- <span class="text-gray text-lg text-thiny">Últ. Actualización <span class="text-white">15 Oct</span> 09:15h</span> -->

            </div>

            <div v-if="records" class="flex-1 flex flex-col px-5">

                <div class="h-1/2 border-b border-gray border-dots flex flex-col px-5 relative">

                    <div v-if="records.saleTop.record" class="absolute top-0 left-0 h-full w-full">
                        <img src="https://media.giphy.com/media/uV6ZueyzR8J6pCdxtZ/giphy.gif" class="h-full w-full">
                    </div>
                
                    <div class="h-auto w-full flex flex-row justify-between items-center">

                        <span class="text-lg text-orange">FACTURACIÓN</span>
                        <span class="text-lightblue uppercase">{{records.saleTop.date | moment('MMMM YYYY')}}</span>

                    </div>

                    <div class="flex-1 flex flex-row justify-between items-center">

                        <i class="mdi mdi-trophy text-orange text-6xl"></i>

                        <div class="h-auto flex flex-col justify-center items-end">
                            <span class="text-white font-semibold text-3xl">{{records.saleTop.amount | reduceBigNumbers()}} €</span>
                            <span class="text-white font-semibold text-3xl">{{records.saleTop.meters | reduceBigNumbers()}} m<sup>2</sup></span>
                        </div>

                    </div>
                
                </div>

                <div class="h-1/2 flex flex-col px-5 relative">

                    <div v-if="records.deliveryNoteTop.record" class="absolute top-0 left-0 h-full w-full">
                        <img src="https://media.giphy.com/media/uV6ZueyzR8J6pCdxtZ/giphy.gif" class="h-full w-full">
                    </div>
                
                    <div class="h-auto w-full flex flex-row justify-between items-center mt-6">

                        <span class="text-lg text-orange">ALBARANES</span>
                        <span class="text-lightblue uppercase">{{records.deliveryNoteTop.date | moment('DD MMMM YYYY')}}</span>

                    </div>

                    <div class="flex-1 flex flex-row justify-between items-center">

                        <i class="mdi mdi-trophy text-orange text-6xl"></i>

                        <div class="h-auto flex flex-col justify-center items-end">
                            <span class="text-white font-semibold text-3xl">{{records.deliveryNoteTop.amount | reduceBigNumbers()}} €</span>
                            <span class="text-white font-semibold text-3xl">{{records.deliveryNoteTop.meters | reduceBigNumbers()}} m<sup>2</sup></span>
                        </div>

                    </div>
                
                </div>

            </div>

        </div>

        <div class="h-20"></div>

        <div class="flex-1 flex flex-row overflow-hidden relative">

            <div class="h-full w-227 flex flex-col overflow-y-hidden">

                <div class="h-16 flex flex-row justify-start items-center px-10">

                    <span class="text-2xl text-aux mr-auto">RANKING VARIACIONES FACTURACIÓN</span>

                </div>

                <div class="flex-1 flex flex-col overflow-y-hidden px-10">

                    <div class="h-auto flex flex-row pl-5 pb-2">

                        <div class="h-full w-8/12 flex flex-row justify-start items-center">
                            <span class="text-aux text-xl">CLIENTE</span>
                        </div>

                        <div class="h-full w-4/12 flex flex-col justify-center items-center">
                            <span class="text-aux text-xl uppercase">Acumulado {{dateClients | moment('MMM YYYY')}}</span>
                        </div>

                    </div>

                    <div class="flex-1 overflow-y-auto ">

                        <clientcard v-for="(el,index) in clients" :key="index" :data="el"/>

                    </div>

                </div>

            </div>

            <div class="h-full flex-1 relative">

                <div class="absolute w-auto px-2 bottom-0 left-1 flex flex-col justify-start items-center">

                    <span class="text-aux text-2xl">FACTURACIÓN</span>

                    <div class="h-auto w-full flex flex-row justify-center items-center">

                        <span class="text-2xl text-facturationtotal capitalize">ACC. {{billingdate | moment('MMM YYYY')}}</span>
                        <span class="text-2xl text-gray mx-2">/</span>
                        <span class="text-2xl text-gray capitalize">{{prev_billingdate  | moment('MMM YYYY')}}</span>
                        
                    </div>

                    <div class="h-auto flex flex-col mt-4 justify-center items-center">

                        <div class="h-24 w-24 rounded-full border-4 border-lightgreen bg-lightgreen bg-opacity-25 flex flex-col justify-center items-center">
                            <span class="text-4xl text-lightgreen font-semibold">+5%</span>
                        </div>

                        <div class="h-2 w-24 rounded-lg bg-neutral my-4"></div>

                        <div class="h-24 w-24 rounded-full border-4 border-bad bg-bad bg-opacity-25 flex flex-col justify-center items-center">
                            <span class="text-4xl text-bad font-semibold">-5%</span>
                        </div>

                    </div>

                </div>

            </div>

        </div>

        <div v-if="europe" class="absolute bottom-0 right-0 h-136 w-158 z-0 -mr-8">

            <europe />

        </div>

        <div v-if="!europe" class="absolute bottom-0 right-0 h-136 w-158 z-0 -mr-8">

            <spain />

        </div>

    </div>

</template>

<script>
import piechart from './charts/piechart.vue';
import clientcard from './clientcard.vue';
import loader from './loader.vue';
import europe from './europe.vue';
import spain from './spain.vue';
import { state } from '@/store';

export default {
    props:['data', 'bubbles'],
    components:{
        piechart,
        clientcard,
        loader,
        europe,
        spain
    },
    data(){
        return{
            donutdata:{
                nacional:0,
                export: 0,
                nacional_prev:0,
                export_prev:0
            },
            loading: false,
            europe: true,
            clients:false,
            year: this.$moment().format('YYYY'),
            month: this.$moment().format('MMMM'),
            records: false,
            recordLoading: false,
            billingdate: false,
            billingdatetitle: false,
            firstTime: true
        }
    },
    methods:{

        load(){

            this.loading = true;
            this.recordLoading = true;

            this.axios.get('sale_by_client/data').then(response => {

                this.clients = response.data.data;
                this.loading = false;

            })

            this.axios.get('record/data').then(response => {

                this.records = response.data.data;
                this.recordLoading = false;

            }).catch(error => {
                this.recordLoading = false;
            })

            this.axios('/last_sale_date/data').then(response => {
                
                this.billingdate = response.data.data.month_expired;
                this.dateClients = response.data.data.month_expired;
                this.prev_billingdate = this.$moment(response.data.data.month_expired).subtract(1, 'years');
                this.billingdatetitle = response.data.data.date;

            })

        }


    },
    computed:{
        objective(){
            return this.bubbles.objective || {}
        },
        mediumPrice(){
            return this.bubbles.mediumPrice || {}
        },
        margin(){
            return this.bubbles.margin || {}
        }
    },
    mounted(){

        this.load();

        setInterval(() => {
            this.europe = !this.europe    
        }, 60000);

        setInterval(() => {
            this.firstTime = false;
            this.load();
        }, state.timeRefresh);

    }
}
</script>

<style>

</style>