<template>

    <div class="h-full w-full flex flex-col relative">

        <loader v-if="firstTime" :loading="loading" class="bg-module" />

        <div class="h-16 flex flex-row justify-start items-center px-10">

            <span class="text-2xl text-aux mr-auto">CONTENEDORES A 5 DÍAS VISTA</span>

            <span v-if="lastSyncro" class="text-gray text-2xl text-thiny">Últ. Actualización <span class="text-white capitalize">{{lastSyncro.date | moment('DD MMM')}}</span> {{lastSyncro.date | moment('HH:mm')}}h</span>

        </div>

        <div class="flex-1 min-h-0 flex flex-row justify-start px-10">

            <div v-for="(el,index) in days" :key="index" class="h-full w-40 flex-none" :class="{'mr-6': index < days.length - 1}">
                <containerchart :day="getData(el.date)" :data="el"/>
            </div>

        </div>

    </div>

</template>

<script>
import containerchart from '../components/charts/containerchart.vue';
import loader from './loader.vue';
import { state } from '@/store';

export default {
    props:['lastSyncro'],
    components:{
        containerchart,
        loader
    },
    data(){
        return{
            loading:false,
            days:false,
            firstTime:true
        }
    },
    methods:{

        load(){

            this.loading = true;
            this.axios.get('/containers/future_days_count', this.form ).then(response => {
                this.loading = false;
                this.days = response.data.data
            })

        },
        getData(date){

            return this.$moment.unix(date).format('dddd')

        }

    },
    mounted(){

        this.load();

        setInterval(() => {
            this.firstTime = false;
            this.load();
        }, state.timeRefresh);
        
    }
}
</script>

<style>

</style>