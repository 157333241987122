<template>

    <div class="h-full w-full flex flex-col">

        <div class="h-10 flex flex-row justify-center items-center border-b border-gray">
            
            <span class="text-gray uppercase text-xl">{{day}}</span>

        </div>

        <div class="h-auto flex flex-col mt-10 mb-6">

            <div class="h-auto flex flex-col mb-4 px-2">

                <div class="h-6 w-full grid grid-cols-5 gap-1">
                    <div class="h-full w-full rounded bg-box" :class="{'bg-green-transparent border-2 border-green': calculate(11) }"></div>
                    <div class="h-full w-full rounded bg-box" :class="{'bg-green-transparent border-2 border-green': calculate(12) }"></div>
                    <div class="h-full w-full rounded bg-box" :class="{'bg-green-transparent border-2 border-green': calculate(13) }"></div>
                    <div class="h-full w-full rounded bg-box" :class="{'bg-green-transparent border-2 border-green': calculate(14) }"></div>
                    <div class="h-full w-full rounded bg-box" :class="{'bg-green-transparent border-2 border-green': calculate(15) }"></div>
                </div> 

            </div>

            <div class="h-auto flex flex-col mb-4 px-2">

                <div class="h-6 w-full grid grid-cols-5 gap-1">
                    <div class="h-full w-full rounded bg-box" :class="{'bg-warning-transparent border-2 border-warning': calculate(6) }"></div>
                    <div class="h-full w-full rounded bg-box" :class="{'bg-warning-transparent border-2 border-warning': calculate(7) }"></div>
                    <div class="h-full w-full rounded bg-box" :class="{'bg-warning-transparent border-2 border-warning': calculate(8) }"></div>
                    <div class="h-full w-full rounded bg-box" :class="{'bg-warning-transparent border-2 border-warning': calculate(9) }"></div>
                    <div class="h-full w-full rounded bg-box" :class="{'bg-warning-transparent border-2 border-warning': calculate(10) }"></div>
                </div> 

            </div>

            <div class="h-auto flex flex-col px-2">

                <div class="h-6 w-full grid grid-cols-5 gap-1">
                    <div class="h-full w-full rounded bg-box" :class="{'bg-danger-transparent border-2 border-danger': calculate(1) }"></div>
                    <div class="h-full w-full rounded bg-box" :class="{'bg-danger-transparent border-2 border-danger': calculate(2) }"></div>
                    <div class="h-full w-full rounded bg-box" :class="{'bg-danger-transparent border-2 border-danger': calculate(3) }"></div>
                    <div class="h-full w-full rounded bg-box" :class="{'bg-danger-transparent border-2 border-danger': calculate(4) }"></div>
                    <div class="h-full w-full rounded bg-box" :class="{'bg-danger-transparent border-2 border-danger': calculate(5) }"></div>
                </div> 

            </div>

        </div>
    
        <div class="h-auto flex-none flex flex-col justify-center items-center mb-1">
            <span class="text-gray text-3xl"> <span class="font-semibold text-white">{{ data.loaded }}/</span>{{ data.cont }}</span>
        </div>

        <div class="flex-1 min-h-0 flex flex-col justify-center items-center">

            <span class="text-xl text-white">{{ data.meters | reduceBigNumbers() }} m<sup>2</sup></span>
            <span class="text-xl text-white">{{ data.price | reduceBigNumbers() }} €</span>

        </div>

    </div>

</template>

<script>
export default {
    props:['day', 'data', 'max'],
    methods:{
        calculate(value){

            const total = 50
            const squares = 15

            if(this.data.cont >= (value * (total / squares))){

                return true

            } else if(value == 1 && this.data.cont > 0){
                
                return true

            } else {

                return false

            }

        }
    }
}
</script>

<style>

</style>