<template>
    
    <div class="h-full w-full py-4 flex flex-col">

        <div class="h-auto flex flex-row justify-start items-center border-b border-gray mt-3">
            <span class="text-gray mr-auto text-2xl uppercase">{{data.day}}</span>
            <span class="text-white text-4xl font-semibold mr-3">{{data.total}}</span>
            <span class="text-4xl text-gray">/ {{data.pending}}</span>
        </div>

        <div class="flex-1 min-h-0 flex flex-row justify-center items-center">

            <div class="h-full w-auto  flex flex-row justify-center items-end py-8">

                <div class="w-6 flex flex-col justify-end border-picking rounded mr-4 bg-picking bg-opacity-25" :style="'height:' + data.by_types.Picking.percentage + '%'">
                    <div class="rounded w-full bg-picking" :style="'height:' + data.by_types.Picking.done_percentage + '%'"></div>
                </div>
                <div class="w-6 flex flex-col justify-end border-brecha rounded mr-4 bg-brecha bg-opacity-25" :style="'height:' + data.by_types.Brecha.percentage + '%'">
                    <div class="rounded w-full bg-brecha" :style="'height:' + data.by_types.Brecha.done_percentage + '%'"></div>
                </div>


            </div>

            <div class="h-full w-auto flex flex-col">
            

                <div class="w-full h-1/2 flex flex-col justify-center items-start">
                
                    <div class="h-auto flex flex-row justify-start items-center">
                        <div class="h-4 w-4 rounded-full bg-picking mr-2"></div>
                        <span class="text-lg text-gray uppercase">Picking</span>
                    </div>

                    <div class="h-auto w-full flex flex-row justify-start items-center">
                        <span class="text-4xl text-white">{{data.by_types.Picking.total}}</span>
                        <span class="text-4xl text-gray mx-2">/</span>
                        <span class="text-4xl text-gray">{{data.by_types.Picking.pending}}</span>
                    </div>
                
                </div>
                <div class="w-full h-1/2 flex flex-col justify-center items-start">
                
                    <div class="h-auto flex flex-row justify-start items-center">
                        <div class="h-4 w-4 rounded-full bg-brecha mr-2"></div>
                        <span class="text-lg text-gray uppercase">Brecha</span>
                    </div>

                    <div class="h-auto w-full flex flex-row justify-start items-center">
                        <span class="text-4xl text-white">{{data.by_types.Brecha.total}}</span>
                        <span class="text-4xl text-gray mx-2">/</span>
                        <span class="text-4xl text-gray">{{data.by_types.Brecha.pending}}</span>
                    </div>
                
                </div>

            </div>

        </div>

    </div>

</template>

<script>
export default {
    props:['data']
}
</script>