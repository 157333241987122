<template>

    <div class="h-full w-full flex flex-col relative">

        <loader v-if="firstTime" :loading="loading" class="bg-module" />

        <div class="h-16 flex flex-row justify-start items-center px-10">

            <span class="text-2xl text-aux mr-auto">FACTURACIÓN MES</span>

            <span v-if="lastSyncro" class="text-gray text-2xl text-thiny">Últ. Actualización <span class="text-white capitalize">{{lastSyncro.date | moment('DD MMM')}}</span> {{lastSyncro.date | moment('HH:mm')}}h</span>

        </div>

        <div class="flex-1 flex flex-row">

            
            <div class="h-full flex-1 flex flex-col px-10">

                <div class="h-full w-full flex flex-col">

                    <div class="h-auto">
                        <span class="text-lightblue text-2xl">FACTURACIÓN (€)</span>
                    </div>

                    <div class="flex-1">

                        <columnchart :data="[data.nationalAmount || 0,data.interAmount || 0,data.totalAmount || 0]" unit="€" :max="getMax(data.nationalAmount,data.interAmount,data.totalAmount)"/>

                    </div>

                </div>

                <div class="h-full w-full mt-5 flex flex-col">

                    <div class="h-auto">
                        <span class="text-lightblue text-2xl">METROS CUADRADOS (m<sup>2</sup>)</span>
                    </div>

                    <div class="flex-1">

                        <columnchart :data="[data.nationalMeter ||0 ,data.interMeter ||0 ,data.totalMeters || 0]" :max="getMax(data.nationalMeter,data.interMeter,data.totalMeters)"/>

                    </div>

                </div>

            </div>

            <div class="h-full w-128 px-10 flex flex-col justify-center items-center">

                <div class="h-full w-full flex flex-col justify-start items-center">

                    <div class="h-auto w-full flex flex-row justify-start items-center">

                        <span class="text-lightblue text-2xl mr-2">ALCANCE (€)</span>

                        <span class="text-2xl text-facturationtotal capitalize">{{billingdate}}</span>
                        <span class="text-2xl text-gray mx-2">/</span>
                        <span class="text-2xl text-gray capitalize">{{prev_billingdate}}</span>
                        
                    </div>

                    <div class="h-auto w-auto flex flex-row justify-center items-center">

                        <div class="h-82 w-82 relative flex flex-col justify-center items-center">
                            <piechart :data="[data.outPercent || 0, data.innerPercent || 0]"/>
                            <div class="h-56 w-56 rounded-full absolute flex flex-col justify-center items-center">
                                <span class="text-white font-semibold text-3xl">{{data.scope || 0 | reduceBigNumbers()}} €</span>
                                <span class="font-semibold text-2xl" :class="{'text-green': data.percent >= 0, 'text-danger': data.percent < 0}"><span v-if="data.percent >= 0">+</span>{{data.percent || 0 | numberFormat(2)}}%</span>
                                <span class="text-gray text-xl -mb-2">Anterior</span>
                                <span class="text-white font-semibold text-3xl">{{data.downValue || 0 |reduceBigNumbers()}} €</span>
                            </div>
                        </div>

                    </div>

                    <div class="flex-1 w-full">

                        <div class="h-full w-full flex flex-row">

                            <div class="h-full w-auto mr-8 ml-auto flex flex-col justify-center items-end">
                                <span class="text-purple text-2xl">Nacional</span>
                                <span class="text-white font-semibold text-3xl">{{data.nationalScope || 0 | reduceBigNumbers()}} €</span>
                                <span class="font-semibold text-2xl" :class="{'text-green': data.nationalPercent >= 0, 'text-danger': data.nationalPercent < 0}"><span v-if="data.nationalPercent >= 0">+</span> {{data.nationalPercent || 0 | numberFormat(2)}}%</span>
                            </div>

                            <div class="h-full w-auto mr-12 flex flex-col justify-center items-end">
                                <span class="text-yellow text-2xl">Exportación</span>
                                <span class="text-white font-semibold text-3xl">{{data.interScope || 0 | reduceBigNumbers()}} €</span>
                                <span class="font-semibold text-2xl" :class="{'text-green': data.interPercent >= 0, 'text-danger': data.interPercent < 0}"><span v-if="data.interPercent >= 0">+</span> {{data.interPercent || 0 | numberFormat(2)}}%</span>
                            </div>

                        </div>

                    </div>

                </div>

            </div>


        </div>

        <div class="h-16 flex flex-row justify-center items-center">

            <div class="h-1 w-16 bg-purple mr-4"></div>
            <span class="text-gray mr-8">Nacional</span>

            <div class="h-1 w-16 bg-yellow mr-4"></div>
            <span class="text-gray mr-8">Exportación</span>

            <div class="h-1 w-16 bg-lightblue mr-4"></div>
            <span class="text-gray">Total</span>

        </div>

    </div>

</template>

<script>
import columnchart from './charts/columnchart.vue';
import piechart from './charts/piechart.vue';
import loader from './loader.vue';
import { state } from '@/store';

export default {
    props:['data', 'loading', 'lastSyncro'],
    components:{
        columnchart,
        piechart,
        loader
    },
    data(){
        return{
            actualmonth: this.$moment().format('MMMM YYYY'),
            prevmonth: this.$moment().subtract(1, 'years').format('MMMM YYYY'),
            billingdate: false,
            prev_billingdate:false,
            firstTime: true
        }
    },
    methods:{

        load(){

            this.axios('/last_sale_date/data').then(response => {

                this.billingdate = this.$moment(response.data.data.date).format('MMM YYYY');
                this.prev_billingdate = this.$moment(response.data.data.date).subtract(1, 'years').format('MMM YYYY')

            })

        },
        getMax(nat,inter,total){

            return (Math.max(nat,inter,total) + 50)

        }

    },
    mounted(){
        this.load();
        setInterval(() => {
            this.firstTime = false;
            this.load();
        }, state.timeRefresh);
    }
}
</script>

<style>

</style>