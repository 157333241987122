<template>
    
    <div class="h-full w-full flex flex-col overflow-hidden relative">

        <loader v-if="firstTime" :loading="loading" class="bg-module"/>

        <div class="h-16 flex flex-row justify-start items-center px-10">

            <span class="text-2xl text-aux mr-auto">INFO LÍNEAS DIARIAS ZONAS</span>
            <span v-if="lastSyncro" class="text-gray text-2xl text-thiny">Últ. Actualización <span class="text-white capitalize">{{lastSyncro.date | moment('DD MMM')}}</span> {{lastSyncro.date | moment('HH:mm')}}h</span>

        </div>

        <div class="flex-1 flex flex-row overflow-hidden">

            <div class="h-full w-1/3 flex flex-col overflow-hidden pl-10">
            
                <div class="h-auto flex flex-row justify-end items-center">
                    <span class="text-aux text-2xl uppercase">España</span>
                    <img src="/img/spain.svg" class="h-8 my-2 ml-4 mr-4">
               </div>

                <div v-if="spain && spain.length > 0" class="flex flex-col flex-1 min-h-0 overflow-auto">

                    <div v-for="(el,index) in spain" :key="index" class="h-16 mb-2 bg-body px-4 rounded flex flex-row justify-start items-center">

                        <div class="h-full w-6/12 flex flex-row justify-start items-center">
                            <div class="h-8 w-8 rounded-full bg-red mr-2" :style="'background-image: url(img/citiesflags/'+ toUppercase(el.iso) +'.svg); background-repeat: no-repeat; background-size: cover; background-position: center;'"></div>
                            <span class="text-2xl text-gray uppercase mr-auto truncate">{{el.name}}</span>
                        </div>

                        <div class="h-full w-3/12 flex flex-row justify-end items-center">
                            <span class="text-3xl text-white mr-6">{{el.line}}</span>
                        </div>
                        
                        <div class="h-full w-4/12 flex flex-row justify-end items-center">
                            <span class="text-3xl text-white">{{el.amount | reduceBigNumbers()}} <small>€</small></span>
                        </div>

                    </div>

                    <div v-if="!spain" class="h-full w-full flex flex-col justify-center items-center">
                        <span class="text-gray text-xl">No hay datos</span>
                    </div>

                </div>

                <div v-else class="flex flex-1 min-h-0 flex-col justify-center items-center">

                    <span class="text-gray">Todavía no existen datos</span>

                </div>

            </div>

            <div class="h-full w-1/3 flex flex-col overflow-hidden px-10">
            
                <div class="h-auto flex flex-row justify-end items-center">
                    <span class="text-aux text-2xl uppercase">Europa</span>
                    <img src="/img/europe.svg" class="h-8 my-2 ml-4 mr-4">
                </div>

                <div v-if="europe && europe.length > 0" class="flex flex-col flex-1 min-h-0">

                    <div v-for="(el,index) in europe" :key="index" class="h-16 mb-2 bg-body px-4 rounded flex flex-row justify-start items-center">

                        <div class="h-full w-6/12 flex flex-row justify-start items-center">
                            <div class="h-8 w-8 rounded-full mr-2" :style="'background-image: url(img/countryflags/'+ toLowercase(el.iso) +'.svg); background-repeat: no-repeat; background-size: cover; background-position: center;'"></div>
                            <span class="text-2xl text-gray uppercase mr-auto truncate">{{el.name}}</span>
                        </div>

                        <div class="h-full w-3/12 flex flex-row justify-end items-center">
                            <span class="text-3xl text-white mr-6">{{el.line}}</span>
                        </div>
                        
                        <div class="h-full w-4/12 flex flex-row justify-end items-center">
                            <span class="text-3xl text-white">{{el.amount | reduceBigNumbers()}} <small>€</small></span>
                        </div>

                    </div>

                    <div v-if="!europe" class="h-full w-full flex flex-col justify-center items-center">
                        <span class="text-gray text-xl">No hay datos</span>
                    </div>

                </div>

                <div v-else class="flex flex-1 min-h-0 flex-col justify-center items-center">

                    <span class="text-gray">Todavía no existen datos</span>

                </div>

            </div>

            <div class="h-full w-1/3 flex flex-col overflow-hidden pr-10">
            
                <div class="h-auto flex flex-row justify-end items-center">
                    <span class="text-aux text-2xl uppercase">Mundo</span>
                    <img src="/img/world.svg" class="h-8 my-2 ml-4 mr-4">
                </div>

                <div v-if="world && world.length > 0" class="flex flex-col flex-1 min-h-0">

                    <div v-for="(el,index) in world" :key="index" class="h-16 mb-2 bg-body px-4 rounded flex flex-row justify-start items-center">

                        <div class="h-full w-6/12 flex flex-row justify-start items-center">
                            <div class="h-8 w-8 rounded-full mr-2" :style="'background-image: url(img/countryflags/'+ toLowercase(el.iso) +'.svg); background-repeat: no-repeat; background-size: cover; background-position: center;'"></div>
                            <span class="text-2xl text-gray uppercase mr-auto truncate">{{el.name}}</span>
                        </div>

                        <div class="h-full w-3/12 flex flex-row justify-end items-center">
                            <span class="text-3xl text-white mr-6">{{el.line}}</span>
                        </div>
                        
                        <div class="h-full w-4/12 flex flex-row justify-end items-center">
                            <span class="text-3xl text-white">{{el.amount | reduceBigNumbers()}} <small>€</small></span>
                        </div>

                    </div>

                    <div v-if="!world" class="h-full w-full flex flex-col justify-center items-center">
                        <span class="text-gray text-xl">No hay datos</span>
                    </div>

                </div>

                <div v-else class="flex flex-1 min-h-0 flex-col justify-center items-center">

                    <span class="text-gray">Todavía no existen datos</span>

                </div>

            </div>

        </div>

    </div>

</template>

<script>
import loader from './loader.vue';
import { state } from '@/store';

export default {
    props:['lastSyncro'],
    components:{
        loader
    },
    data(){
        return{
            spain:false,
            europe:false,
            world:false,
            loading:false,
            firstTime: true
        }
    },
    methods:{
        load(){
            
            this.loading = true;

            this.axios.get('/order/zone').then(response => {

                this.spain = response.data.data.province
                this.europe = response.data.data.country
                this.world = response.data.data.continent

                this.loading = false;

            })

        },
        toUppercase(name){
            return name.toUpperCase()
        },
        toLowercase(name){
            return name.toLowerCase()
        }
    },
    mounted(){
        this.load();

        setInterval(() => {
            this.firstTime = false;
            this.load();
        }, state.timeRefresh);
    }
}
</script>