<template>
  
    <div class="h-full w-full flex flex-col px-10 relative">

        <loader v-if="firstTime" :loading="loading" class="bg-module"/>

        <div class="h-16 flex flex-row justify-start items-center">

            <span class="text-2xl text-aux mr-auto">LINEAS LANZADAS A PREPARAR - Totales / Pendientes</span>
            <span v-if="lastSyncro" class="text-gray text-2xl text-thiny">Últ. Actualización <span class="text-white capitalize">{{lastSyncro.date | moment('DD MMM')}}</span> {{lastSyncro.date | moment('HH:mm')}}h</span>

        </div>

        <div class="flex-1 flex flex-row">

            <div v-for="(el,index) in thrownlinesdata" :key="index" class="h-full w-1/5">
                <thrownchart :data="el" :class="{'px-6': index != 0 && index != (thrownlinesdata.length - 1), 'pr-6': index == 0, 'pl-6': index == (thrownlinesdata.length - 1)}"/>
            </div>

            <div v-if="!thrownlinesdata" class="h-full w-full flex flex-col justify-center items-center">
                <span class="text-gray text-xl">No hay datos</span>
            </div>

        </div>

    </div>

</template>

<script>
import thrownchart from '../components/charts/thrownchart.vue';
import loader from './loader.vue';
import { state } from '@/store';

export default {
    props:['lastSyncro'],
    components:{
        thrownchart,
        loader
    },
    data(){
        return{

            thrownlinesdata: false,
            loading: false,
            firstTime: true
        }
    },
    methods:{
        
        load(){

            this.loading = true;

            this.axios.get('/to_prepare').then(response => {
                this.thrownlinesdata = response.data.data;
                this.loading = false;
            })

        }

    },
    mounted(){

        this.load();

        setInterval(() => {
            this.firstTime = false;
            this.load();
        }, state.timeRefresh);
    }
}
</script>

<style>

</style>