<template>
  
    <div class="h-full w-full flex flex-row justify-center items-center">

        <div class="h-full w-48 flex flex-col justify-start items-start">

            <div class="h-auto flex flex-row justify-start items-center">

                <div class="h-4 w-4 rounded-full mr-2" :class="'bg-'+data.color"></div>

                <span class="text-2xl text-gray uppercase">{{data.name}}</span>

            </div>

            <div class="h-32 w-full flex flex-col justify-center items-center pr-10">

                <span class="text-5xl text-white font-semibold">{{data.line  || 0}}</span>
                <span class="text-2xl text-gray -mt-4">líneas</span>

            </div>

            <div class="flex-1 flex w-full pr-10 flex-col justify-start items-center">

                <span v-if="data.name != 'MUESTRAS'" class="text-3xl text-white font-semibold">{{data.amount || 0 | reduceBigNumbers()}} €</span>

            </div>

        </div>

        
        <div class="bg-box w-20 px-5 flex flex-col justify-end items-center relative mt-auto" :style="'height:' + data.height + '%'">

            <div class="h-auto w-full absolute top-0 left-0 mb-auto -mt-24 flex flex-col justify-end items-center">
                <span class="text-4xl text-gray -mb-3 mt-6">{{data.max || 0}}</span>
                <span class="text-2xl text-gray">líneas</span>
            </div>

            <div class="w-full rounded" :class="'bg-'+data.color" :style="'height:'+data.line_percentage+'%'"></div>

            <div class="w-full h-1 absolute bg-aux2 shadow" :style="'bottom:'+data.avg_percentage+'%'"></div>

        </div>

        <div class="flex flex-col justify-end items-center relative w-20 mt-auto" :style="'height:' + data.height + '%'">

            <div class="w-full absolute flex flex-col justify-start items-center" :style="'height:'+(data.avg_percentage + 7)+'%'">
                <span class="text-aux2 font-semibold text-4xl mx-2">{{data.avg || 0}}</span>
                <span class="text-gray text-2xl -mt-4">líneas</span>
            </div>

        </div>
        
    </div>

</template>

<script>
export default {
    props:['data']
}
</script>

<style>

</style>