<template>

    <div class="h-full w-full relative">

        <loader v-if="firstTime" :loading="loading" class="bg-body"/>

        <worldmapchart :data="worldMapData"/>

        <div class="absolute w-auto px-2 bottom-0 left-0 mb-40 flex flex-col justify-start items-center">

            <span class="text-aux text-2xl">FACTURACIÓN</span>

            <div class="h-auto w-full flex flex-row justify-center items-center">

                <span class="text-2xl text-facturationtotal capitalize">ACC. {{billingdate | moment('MMM YYYY')}}</span>
                <span class="text-2xl text-gray mx-2">/</span>
                <span class="text-2xl text-gray capitalize">{{prev_billingdate  | moment('MMM YYYY')}}</span>
                
            </div>

            <div class="h-24 w-24 rounded-full mt-6 border-4 border-lightgreen bg-lightgreen bg-opacity-25 flex flex-col justify-center items-center">
                <span class="text-4xl text-lightgreen font-semibold">+5%</span>
            </div>

            <div class="h-2 w-24 rounded-lg bg-neutral my-4"></div>

            <div class="h-24 w-24 rounded-full border-4 border-bad bg-bad bg-opacity-25 flex flex-col justify-center items-center">
                <span class="text-4xl text-bad font-semibold">-5%</span>
            </div>

        </div>

    </div>

</template>

<script>
import worldmapchart from './charts/worldmapchart.vue';
import loader from './loader.vue';
import { state } from '@/store';

export default {
    components:{
        worldmapchart,
        loader
    },
    data(){
        return{
            loading: false,
            worldMapData: false,
            billingdate: false,
            prev_billingdate: false,
            firstTime: true
        }
    },
    methods:{

        load(){
            this.axios.get('/sale_by_country/data').then(response => {
                this.worldMapData = response.data.data;
            })
            this.axios('/last_sale_date/data').then(response => {

                this.billingdate = response.data.data.month_expired;
                this.prev_billingdate = this.$moment(response.data.data.month_expired).subtract(1, 'years')

            })
        }
    },
    mounted(){
        this.load();
        setInterval(() => {
            this.firstTime = false;
            this.load();
        }, state.timeRefresh);
    }
}
</script>

<style>

</style>