  
<template>
    <div class="h-full w-full" ref="container-chart">
        <highcharts :options="options" ref="gaugeChart" class="chart-component h-full w-full" />
    </div>

</template>

<script>
let gradient ={
    linearGradient: {x1: 0,x2: 0,y1: 1,y2: 0},
    stops: [[0, '#21223387'],[1, '#6A85B187']]
}
let labelValue = 125;
import moment from 'moment';
import {reduceBigNumbers} from '../../main.js';
import { numberFormat } from 'highcharts';

export default {
    props:['data', 'margin', 'plotline', 'values'],
    data(){
        return{
            randomdata:[],
            lastPointValue:false
        }
    },
    methods:{
        lastPoint(){

            let value = false

            for (let index = 0; index < this.data[1].length; index++) {

                if(this.data[1][index][1] == null && this.data[1][index-1][1] != null){
                    value = this.data[1][index-1]
                }
                
            }

            this.lastPointValue = value

        },
        getColor(value){

            if(value <= 4.5){

                if(value <= 3.5){

                    return '#6DAC5F'

                } else {

                    return '#ffe65d'

                }

            } else {

                return '#E65454'

            }

        },
        number(number){

            if(number>=0){
                return '+'+number
            } else {
                return number
            }

        }
    },
    computed:{
        options(){
            return{
                chart: {
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    margin:this.margin ? this.margin : [30,50,50,110]
                },
                title: {
                    text: ''
                },
                subtitle: {
                    text: ''
                },
                xAxis: [
                    {
                        type: 'datetime',
                        tickInterval: !this.values ? undefined : 2629750000,
                        labels: 
                        this.values ? 
                            {
                                formatter: function (){
                                    return '<span class="uppercase -mt-2">' + moment(this.value).format('MMM') + '</span>'
                                },
                                x: 0,
                                y: 30
                            }
                            :
                            {
                                // formatter: function (){
                                //     return ''
                                // }
                            }
                    },
                    {
                        opposite:true,
                        lineColor: 'transparent',
                        tickColor: 'transparent',
                        labels:{
                            formatter: function () {
                                return ''
                            }
                        },
                        plotLines: this.values ? [
                            {
                                value: (this.values && this.data[2] && this.data[2].date) ? this.data[2].date : this.lastPointValue[0],
                                color: 'gray',
                                width: 2,
                                dashStyle: 'longdash',
                                label: {
                                    text: this.data[2].date ? this.number(numberFormat(this.data[2].percentage,1)) + ' %' : reduceBigNumbers(this.data[2],1) + '<br> <span style="font-size: 25px">meses</span>',
                                    style:{
                                        color: this.data[2].date ? '#159782' : this.getColor(this.data[2]),
                                        fontSize: '35px',
                                        fontWeight: '900'
                                    },
                                    x: this.xLinesAlign,
                                    y: 80,
                                    rotation: 0,
                                    align: this.linesAlign
                                },
                                zIndex:5
                            },
                            {
                                value: (this.values && this.data[2] && this.data[2].date) ? this.data[2].date : this.lastPointValue[0],
                                color: 'gray',
                                width: 2,
                                dashStyle: 'longdash',
                                label: {
                                    text: this.data[2].date ? reduceBigNumbers(this.data[2].current_amount) + ' €' : '',
                                    style:{
                                        color: '#39AFC6',
                                        fontSize: '35px',
                                        fontWeight: '900'
                                    },
                                    align: this.linesAlign,
                                    x: this.xLinesAlign,
                                    y: 0,
                                    rotation: 0,
                                }
                            },
                            {
                                value: (this.values && this.data[2] && this.data[2].date) ? this.data[2].date : this.lastPointValue[0],
                                color: 'gray',
                                width: 2,
                                dashStyle: 'longdash',
                                label: {
                                    text: this.data[2].date ? reduceBigNumbers(this.data[2].previous_amount) + ' €' : '',
                                    style:{
                                        color: '#848A9D',
                                        fontSize: '35px',
                                        fontWeight: '900'
                                    },
                                    align: this.linesAlign,
                                    x: this.xLinesAlign,
                                    y: 40,
                                    rotation: 0,
                                }
                            }
                        ] : undefined,
                        type: 'datetime'
                    }
                ],
                yAxis: [{
                    tickAmount:5,
                    title: '',
                    gridLineColor: 'rgba(132, 138, 157, .1)',
                    labels:{
                        formatter: function (){
                            return '<span class="text-xl">' + reduceBigNumbers(this.value,0) + '</span>'
                        },
                    }
                    }, { // Secondary yAxis
                    gridLineColor: 'rgba(0, 0, 0, .4)',
                    opposite: false,
                    visible:false,
                }],
                tooltip: {
                    enabled: true,
                    shared: true,
                    formatter: function(){
                        return '<span class="text-xl">' + moment(this.x).format('DD MMM YYYY') + '  <span class="font-semibold text-xl">' + reduceBigNumbers(this.y) + '</span></span>'
                    }
                },
                legend: {
                    enabled:false
                },
                plotOptions: {
                    areaspline: {
                        marker: {
                            enabled: false
                        }
                    },
                    spline: {
                        marker: {
                            enabled: false
                        }
                    }                
                },
                credits:{
                    enabled:false
                },
                series: [
                {
                    name: 'Past',
                    type: 'areaspline',
                    xAxis: 0,
                    data: this.data[0] ? this.data[0] : [],
                    color:gradient

                }, {
                    name: 'Current',
                    type: 'spline',
                    data: this.data[1] ? this.data[1] : [],
                    xAxis:1,
                    color:'#39AFC6',
                    lineWidth:6
                },
                {
                    name: '',
                    type: 'line',
                    data: this.lastPointValue ? [this.lastPointValue] : null,
                    xAxis:1,
                    color: this.data[2].date ? '#39AFC6' : '#E65454',
                    marker: {
                        radius: 7,
                        symbol: 'circle'
                    }
                }
                ]
            }
        },
        linesAlign(){

            if(moment(this.data[2].date).format('MMM') == 'DIC'){

                return 'right'

            } else {

                return 'left'

            }

        },
        xLinesAlign(){

            if(moment(this.data[2].date).format('MMM') == 'DIC'){

                return -10

            } else {

                return 10

            }

        }

    },
    watch:{
        data(){
            this.lastPoint();
        }
    },
    mounted(){
        this.lastPoint();
    }
}
</script>