<template>
    <div class="h-full w-full" ref="container-chart">
        <highcharts :options="options" ref="columnchart" class="chart-component h-full w-full" />
    </div>
</template>

<script>
export default {
    props:['data', 'color'],
    data(){
        return{

        }
    },
    methods:{

    },
    computed:{
        options(){
            return{
                chart: {
                    plotBackgroundColor: null,
                    plotBorderWidth: 0,
                    plotShadow: false,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    margin: [0,0,0,0]
                },
                title: {
                    text: '',
                },
                credits:{
                    enabled: false
                },
                legends:{
                    enabled: false
                },
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
                    enabled: false
                },
                accessibility: {
                    point: {
                        valueSuffix: '%'
                    }
                },
                plotOptions: {
                    pie: {
                        dataLabels: {
                            enabled: false,
                            distance: -50,
                            style: {
                                fontWeight: 'bold',
                                color: 'white'
                            }
                        },
                        startAngle: 0,
                        endAngle: 360,
                        center: ['50%', '50%'],
                        size: '100%',
                        borderWidth: 0,
                        
                    }
                },
                series: [{
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '80%',
                    data: [

                        {
                            name: 'Exportación',
                            y: this.data[1] < this.data[0] ? this.data[1] : this.data[0],
                            dataLabels: {
                                enabled: false
                            },
                            color: this.color ? this.color[0] : '#39AFC6'
                        },
                        {
                            name:'',
                            y: 100 - this.data[0],
                            dataLabels: {
                                enabled: false
                            },
                            color: '#2B2B3C'
                        }
                        
                    ]
                },
                {
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '80%',
                    data: [

                        {
                            name: '',
                            y: this.data[1] > this.data[0] ? this.data[0] : 0,
                            dataLabels: {
                                enabled: false
                            },
                            color: 'transparent'
                        },
                        {
                            name: '',
                            y: this.data[1] > this.data[0] ? (this.data[1] - this.data[0]) : 0,
                            dataLabels: {
                                enabled: false
                            },
                            color: '#E65454'
                        },
                        {
                            name:'',
                            y: this.data[1] > this.data[0] ? 100 - (this.data[0] + (this.data[1] - this.data[0]) ) : 0,
                            dataLabels: {
                                enabled: false
                            },
                            color: '#2B2B3C'
                        }
                        
                    ]
                },
                {
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '80%',
                    data: [

                        {
                            name: '',
                            y: this.data[0] > this.data[1] ? this.data[1] : 0,
                            dataLabels: {
                                enabled: false
                            },
                            color: 'transparent'
                        },
                        {
                            name: '',
                            y: this.data[0] > this.data[1] ? (this.data[0] - this.data[1]) : 0,
                            dataLabels: {
                                enabled: false
                            },
                            color: '#6ECAA4'
                        },
                        {
                            name:'',
                            y: this.data[0] > this.data[1] ? (100 - this.data[1] - (this.data[0] - this.data[1])) : 0,
                            dataLabels: {
                                enabled: false
                            },
                            color: '#2B2B3C'
                        }
                        
                    ]
                },
                {
                    type: 'pie',
                    name: 'Browser share',
                    innerSize: '79%',
                    size: '75%',
                    data: [
                        {
                            name: 'Exportación',
                            y: this.data[1],
                            dataLabels: {
                                enabled: false
                            },
                            color: this.color ? this.color[1] : '#39AFC650'
                        },
                        {
                            name:'',
                            y: (100-(this.data[1])),
                            dataLabels: {
                                enabled: false
                            },
                            color: '#2B2B3C'
                        }
                        
                    ]
                }
                ]
            }
        }
    }
}
</script>